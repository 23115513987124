$( document ).ready(function() {

  $(".opportunities-part").each(function(){
      let opportunities = $(this);

      // Create slick carousel if there are more than 3 slides
      // Or screen width is <= 992px
      if(opportunities.find(".slide").length > 3){
        $(opportunities).find('.opportunities__slider').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
  
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  }
                },
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
                }
            ]
        });
      } else {
        // If there are less than 3 slides, add class "notSlider"
        opportunities.addClass("notSlider");
      }
  });

});