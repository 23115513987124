$( document ).ready(function() {

    // Get the link with "active" class
    var asideCurrentPage = $(".aside__wrapper ul li a.active");

    // Prepend the text of the current page into the button
    $(".aside__wrapper .btn-current-page").prepend(asideCurrentPage.text());

    // Open popup menu
    $(".aside__wrapper .btn-current-page").on( "click", function() {
        $(this).next("ul").toggle();
        $(this).toggleClass("active");
    });

});