$( document ).ready(function() {
    $(".fullscreen-modal").each(function(){
        let fullscreenModal = $(this);
        let btnClose = fullscreenModal.find(".btn-close");

        // Close fullscreenModal by clicking on the "X" button
        btnClose.on("click",function(){
            closeFullscreenModal();
        });
        
        // Close fullscreenModal by clicking on the page header
        $(".site-header").on("click",function(){
            closeFullscreenModal();
        });
        
        // Close fullscreenModal by clicking on the "Go back" button
        fullscreenModal.find(".btn-go-back").on("click",function(){
            closeFullscreenModal();
        });

        // Update the "btn-close" position
        if ($(window).width() > 992) {
            btnClosePosition();
        }

        $(window).on('resize', function(){
            if ($(window).width() > 992) {
                btnClosePosition()
            } else {
                btnClose.css({left: '', right: '30px'});
            }
        });
        
        function btnClosePosition(){
            btnClose.css({left: $(".site-header .btn-menu").offset().left, position:'fixed'});
        }
    });
});

function openFullscreenModal(){
    $(".fullscreen-modal").addClass("active");
    $(".site-header").addClass("site-header--fullscreen-modal");
    $("body").css("overflow-y","hidden");

    // close popup-menu (mobile)
    $(".popup-menu").removeClass("active");
}

function closeFullscreenModal(){
    $(".fullscreen-modal").removeClass("active");
    $(".site-header").removeClass("site-header--fullscreen-modal");
    $("body").css("overflow-y","");
}