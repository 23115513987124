$( document ).ready(function() {

    // Open Technologies drawer
    $("button.filter-tech").on( "click", function() {
        CloseDrawer($(".filter-drawer"))
        OpenDrawer($(".filter-drawer--tech"))
    });

    // Open Markets drawer
    $("button.filter-markets").on( "click", function() {
        CloseDrawer($(".filter-drawer"))
        OpenDrawer($(".filter-drawer--markets"))
    });

    // Close drawer
    $(".filter-drawer .btn-close").on( "click", function() {
        CloseDrawer($(".filter-drawer"))
    });

    // Open drawer function
    function OpenDrawer(drawer){
        drawer.addClass("active");
    }

    // Close any drawer
    function CloseDrawer(drawer){
        drawer.removeClass("active");
    }

    // Select technology filter
    $(".filter-drawer--tech ul li button").on("click" , function() {
        $(".page__header .filter-tech span").text($(this).text());

        if($(this).hasClass("btn-all")){
            $(".page__header .filter-tech").removeClass("active")
        } else {
            $(".page__header .filter-tech").addClass("active")
        }

        CloseDrawer($(".filter-drawer"));
    });

    // Select market filter
    $(".filter-drawer--markets .content button").on("click" , function() {
        $(".page__header .filter-markets span").text($(this).text());

        if($(this).hasClass("btn-all")){
            $(".page__header .filter-markets").removeClass("active");
        } else {
            $(".page__header .filter-markets").addClass("active");
        }

        CloseDrawer($(".filter-drawer"));
    });


});