function shareButton () {
	if (document.querySelector('.share') !== null) {
		const shareButton = document.querySelector('.share-button')
		const shareList = document.querySelector('.share-list')
		const listItems = shareList.querySelectorAll('li > a')
		const listWrapper = document.querySelector('.list-wrapper')

		function closeShareList () { // closes the list + accessibility
			shareList.classList.remove('open')
			listWrapper.classList.remove('open')
			shareButton.setAttribute('aria-expanded', false)
			document.querySelector('.share__text').textContent = "Share this event"

			listItems.forEach(item => {
				item.setAttribute('tabindex', '-1') // hides the list from tab browsing
			})
		}

		window.addEventListener('click', function (e) { // closes the list when clicking outside of the element
			if (!shareList.contains(e.target) && !shareButton.contains(e.target)) {
				closeShareList()
			}
		})

		shareButton.addEventListener('click', () => { // opens the list + accessibility
			shareList.classList.add('open')
			listWrapper.classList.add('open')
			shareButton.setAttribute('aria-expanded', true)

			if (window.innerWidth <= 992) {
				document.querySelector('.share__text').textContent = " "
			} else {
				document.querySelector('.share__text').textContent = "Share this event"
			}

			listItems.forEach(item => {
				item.setAttribute('tabindex', '0') // returns the items to tab browsing context
			})
		})


		listItems.forEach(item => { //Adds event listener for closing the list to all list items

			item.addEventListener('click', (e) => {
				closeShareList()
			})
		})

		document.addEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				//if esc key was not pressed in combination with ctrl or alt or shift
				const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey);
				if (isNotCombinedKey) {
					closeShareList()
				}
			}
		})

		if (document.querySelector('.list-wrapper').classList.contains('open') && window.innerWidth < 992) {
			document.querySelector('.share__text').textContent = " "
		} else {
			document.querySelector('.share__text').textContent = "Share this event"
		}
	}
}

shareButton()
