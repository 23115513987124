$( document ).ready(function() {
    $(".accordion-group .accordion").each(function(){
        let accordion = $(this);
        let openButton = $(this).find("> button");

        $(openButton).on("click", function() {
            accordion.toggleClass("active");
            accordion.find(".hidden-content").slideToggle("fast");
        });

    });

});