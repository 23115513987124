$( document ).ready(function() {

    $(".copy-on-click").each(function(){
        let textToCopy = $(this).attr("copy-string");
        let copyTooltip = "<div class='p2 copy-box'>Click to copy</div>"

        // Show tooltip on hover
        $(this).hover(function(){
            $(this).append(copyTooltip);
            }, function() {
            $(this).find(".copy-box").remove();
        });

        // Copy to clipboard
        $(this).on( "click", function( event ) {
            event.preventDefault();
            copyToClipboard(textToCopy);
            $(this).find(".copy-box").text("Copied!");

            // On mobile, hide and delete "copy" popup after 1 sec
            if ($(window).width() <= 992 && $(".copy-box")) {
                $(".copy-box").delay(1000).fadeOut(400);
                setTimeout(() => {
                    $(".copy-box").remove();
                }, 1401);
             }
        });
    });

    // Copy to clipboard function
    function copyToClipboard(text) {
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val(text).select();
        document.execCommand("copy");
        $temp.remove();
    }

});