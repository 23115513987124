function exportPdf (serviceUrl, title) {
	const params = new URLSearchParams(window.location.search)
	const searchTerm = params.get('searchTerm') || ''
	const capability = params.get('capability') || ''
	const category = params.get('category') || ''
	const subcategory = params.get('subcategory') || ''
	const sort = params.get('sort') || ''

	const fetchUrl = `${location.origin}${location.pathname}`

	let searchServiceUrl = `${serviceUrl}?url=${fetchUrl}`
	searchServiceUrl = `${searchServiceUrl}&page=1&pageSize=99999`

	searchServiceUrl = searchTerm ? `${searchServiceUrl}&searchTerm=${searchTerm}` : searchServiceUrl
	searchServiceUrl = capability ? `${searchServiceUrl}&capability=${capability}` : searchServiceUrl
	searchServiceUrl = category ? `${searchServiceUrl}&category=${category}` : searchServiceUrl
	searchServiceUrl = subcategory ? `${searchServiceUrl}&subcategory=${subcategory}` : searchServiceUrl
	searchServiceUrl = sort ? `${searchServiceUrl}&sort=${sort}` : searchServiceUrl

	const pdfButton = $('.pdf-download-btn button')

	pdfButton.text(pdfButton.data('downloadRunning'))
	pdfButton.addClass('downloading')

	$.ajax({
		type: 'POST',
		url: searchServiceUrl,
		dataType: 'json',
		processData: true,
		data: {},
		success: function (data) {
			if (!data.content) {
				pdfButton.text(pdfButton.data('downloadBegin'))
				pdfButton.removeClass('downloading')
				return
			}

			const a = document.createElement('a')
			a.href = 'data:application/pdf;base64,' + data.content
			a.download = `${title}.pdf`
			a.click()

			pdfButton.text(pdfButton.data('downloadBegin'))
			pdfButton.removeClass('downloading')
		},
		error: function () {
			console.error('Error downloading pdf !')
			pdfButton.text(pdfButton.data('downloadBegin'))
			pdfButton.removeClass('downloading')
		}
	})
}
