$( document ).ready(function() {

    // Open filter overlay
    $(".open-mobile-overlay").on( "click", function() {
        $(this).siblings(".mobile-overlay").addClass("active");
    });

    $(".mobile-overlay .cancel , .mobile-overlay .close-filter , .mobile-overlay__footer .btn--primary , .btn-close")
    .on( "click", function() {
        $(".mobile-overlay").addClass("popup-slideDown");
        setTimeout(function(){
          $(".mobile-overlay").removeClass("popup-slideDown active");
        }, 400);
    });
});