$( document ).ready(function() {

    let siteHeader = $(".site-header");
    let headerHeight = siteHeader.outerHeight();
    
    $( window ).resize(function() {
        // Update headerHeight value on window resize
        headerHeight = siteHeader.outerHeight();
    });

    // Check scroll direction (up or down)
    let lastScrollTop = 0;
    $(window).scroll(function (event) {
        let st = $(this).scrollTop();
        if (st > lastScrollTop) {
            // Downscroll code
            if($(document).scrollTop() > headerHeight){
                // Fix the header
                siteHeader.addClass("fixed");
                $(".page").css("padding-top", headerHeight);

                // Hide fixed header
                if(siteHeader.hasClass("show")){
                    siteHeader.removeClass("show");
                }
            }
        } else {
            // Upscroll code
            if(siteHeader.hasClass("fixed") && $(document).scrollTop() > headerHeight){
                // Show fixed header
                siteHeader.addClass("show");
            }
                                    
            if(siteHeader.hasClass("fixed") && $(document).scrollTop() == 0){
                // Make header relative again
                siteHeader.removeClass("fixed");
                siteHeader.removeClass("show");
                $(".page").css("padding-top", "");
            }
        }
        lastScrollTop = st;
    });

    // Open Search menu
    let searchMenu = $(".hidden-menu--search");
    let headerSearchInput = $('#header-search-input');
    $(".site-header .btn-search , .hidden-menu .btn-search").on( "click", function() {
        $(".hidden-menu").fadeOut("slow");
        openOverlayBackground();
        searchMenu.fadeIn();
        headerSearchInput.focus();
        headerSearchInput.select();
    });

    // Open Site menu
    let siteMenu = $(".hidden-menu--menu");
    $(".site-header__content .btn-menu").on( "click", function() {
        $(".hidden-menu").fadeOut("slow");
        siteMenu.fadeIn();
        openOverlayBackground();
    });

    // Open "Partner login" menu
    let loginMenu = $(".hidden-menu--login");
    $(".site-header .btn-login , .hidden-menu .btn-login").on( "click", function() {
        $(".hidden-menu--login").removeClass("forgotten-password");
        $(".hidden-menu").fadeOut("slow");
        loginMenu.fadeIn();
        openOverlayBackground();
    });

    // Show "Forgotten password" form
    $(".login-form__wrapper .btn-forgot-password").on( "click", function() {
        $(".hidden-menu--login").addClass("forgotten-password");
    });

    // Open "Register User"
    let registerUser = $(".hidden-menu--register-user");
    $(".hidden-menu--login .new-user").on( "click", function() {
        $(".hidden-menu").fadeOut("slow");
        registerUser.fadeIn();
        openOverlayBackground();
    });

    // Open "Your Interests"
    // let yourInterests = $(".hidden-menu--your-interests");
    // $(".hidden-menu--register-user .btn-register").on( "click", function() {
    //     $(".hidden-menu").fadeOut("slow");
    //     yourInterests.fadeIn();
    //     openOverlayBackground();
    // });

    // ------------------------------------------------------------

    // Close any hidden menu by clicking on the "X" button or "Cancel" button
    $(".hidden-menu .btn-close , .hidden-menu .go-back , .hidden-menu .btn-cancel").on( "click", function() {
        $(this).closest(".hidden-menu").fadeOut();
        closeOverlayBackground();
    });
    
    // Close any menu by pressing "ESC"
    $(document).keyup(function(e) {
        if (e.key === "Escape") {
            $(".hidden-menu").fadeOut("fast");
            closeOverlayBackground();
       }
    });

    // Function to create and open the background overlay
    function openOverlayBackground(){
        $(".overlay-background").remove();
        let overlayBackground = "<div class='overlay-background'></div>"
        $("body").prepend(overlayBackground);
        $(".overlay-background").fadeIn();

        // Close any menu by clicking on the background overlay
        $(".overlay-background").on( "click", function() {
            $(".hidden-menu").fadeOut();
            closeOverlayBackground();
        });
    }
    
    // Function to fade-out and remove the background overlay
    function closeOverlayBackground(){
        $(".overlay-background").fadeOut("slow", function() {
            $(this).remove();
        });
    }

});