$( document ).ready(function() {

    // FORM Dynamic label
    $('form.dynamic-label input').focus(function(){
        $(this).parents('.form-group').addClass('focused');
      });
      
      $('form.dynamic-label input').blur(function(){
        var inputValue = $(this).val();
        if ( inputValue == "" ) {
          $(this).removeClass('filled');
          $(this).parents('.form-group').removeClass('focused');  
        } else {
          $(this).addClass('filled');
        }
    })  

});