$( document ).ready(function() {
    $(".my-page__company-profile .edit-company").on( "click", function() {
        // Show edit inputs
        $(".my-page__company-profile").addClass("edit");

        // Smooth scroll
        $("html").css("scroll-behavior","smooth");
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".my-page__company-profile").offset().top
        }, 20);
    });
});