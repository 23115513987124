$(document).ready(function () {

  // POPUP Menu
  // Open Popup menu
  $(".open__popup-menu").each(function () {
    $(this).on("click", function () {
      let popupMenu = $(this).next(".popup-menu");

      // Open
      if (!popupMenu.hasClass("active")) {
        popupMenu.addClass("active");
      }
      // Close
      else {
        $(".popup-menu").removeClass("active");
        $(".popup-menu").css("top", "");
      }

      // If the drop-down doesn't fit in viewport when open,
      // then the drop-down should open upwards (DESKTOP ONLY)
      if ($(window).width() > 992 && popupMenu.hasClass("active")) {
        let popupMenu_height = popupMenu.outerHeight();

        if (popupMenu.offset().top + popupMenu_height > $(window).scrollTop() + $(window).height()) {
          popupMenu.css("top", - popupMenu_height);
        }
      }
    });

    // Hide popup menu when hovering out
    $(this).parent().hover(function () {
      // --
    }, function () {
      $(".popup-menu").removeClass("active");
      $(".popup-menu").css("top", "");
    });

    // Close popup menu when clicking btn-close (MOBILE)
    $(".popup-menu .btn-close").on("click", function () {
      $(".popup-menu").addClass("popup-slideDown");
      setTimeout(function () {
        $(".popup-menu").removeClass("popup-slideDown active");
      }, 400);
    });
  });


  // Custom Select
  var customSelect = $(".custom-select");

  customSelect.each(function () {
    var thisCustomSelect = $(this),
      options = thisCustomSelect.find("option"),
      firstOptionText = options.first().text();

    var selectedItem = $("<div></div>", {
      class: "selected-item"
    });

    if (String(thisCustomSelect.data('hideSelectedItem')) === 'true') {
      selectedItem.css('display', 'none');
    }
    selectedItem
      .appendTo(thisCustomSelect)
      .text(firstOptionText);

    var allItems = $("<div></div>", {
      class: "all-items all-items-hide"
    }).appendTo(thisCustomSelect);

    var allItemsWrapper = $("<div></div>", {
      class: "items__wrapper"
    }).appendTo(allItems);

    options.each(function () {
      var that = $(this),
        optionText = that.text();

      var item = $("<div></div>", {
        class: "item",
        on: {
          click: function () {

            allItems.find(".item").removeClass("selected");
            $(this).addClass("selected");

            selectedItem.addClass("hasSelected");

            var selectedOptionText = that.text();
            selectedItem.text(selectedOptionText);

            // Hide "all-items" if it's not inside a "custom-select--overlay-mobile"
            if (!$(this).closest(".custom-select").hasClass("custom-select--overlay-mobile") || $(window).width() > 992) {
              selectedItem.removeClass("arrowanim");
              allItems.addClass("all-items-hide");
            }

            // Change the "selected" attribute from options when selecting an item
            options.each(function () {
              // remove attribute "selected" from all options
              $(this).removeAttr("selected");

              // add attribute "selected" to the selected option
              if($(this).text() == selectedItem.text()){
                $(this).attr('selected', 'selected');
              }
            })
          }
        }
      })
        .appendTo(allItemsWrapper)
        .text(optionText);

        // check if the option already has the "selected" attribute
        if($(this).attr("selected")){
          allItems.find(".item").each(function () {
            if($(this).text() == optionText){
              // add class "selected" from the selected item
              $(this).addClass("selected");
              selectedItem.addClass("hasSelected");
              selectedItem.text(optionText);
            } else {
              // remove class "selected" from all the other items
              $(this).removeClass("selected");
            }
          })
        }
    });

    // Overlay mobile HTML (works together with "mobile-overlay.js")
    var overlayBtnClose = "<button aria-label='Close' type='button' class='btn-close'><i class='icon icon--x-black'></i></button>"
    var overlayFooter = "<div class='col-12 mobile-overlay__footer'><button class='btn btn--primary' type='button'>Confirm</button><button class='cancel' type='button'>Cancel</button></div>"
    if ($(this).hasClass("custom-select--overlay-mobile")) {
      $(this).find(".all-items").addClass("mobile-overlay");
      $(this).find(".all-items .items__wrapper").append(overlayFooter);
      $(this).find(".all-items .items__wrapper").prepend(overlayBtnClose);
    }

  });

  var selectedItem = $(".selected-item");
  var allItems = selectedItem.parent().find(".all-items");

  selectedItem.on("click", function (e) {
    var currentSelectedItem = $(this);
    var currentAllItems = currentSelectedItem.parent().find(".all-items");

    if ($(window).width() >= 992) {
      allItems.not(currentAllItems).addClass("all-items-hide");
      selectedItem.not(currentSelectedItem).removeClass("arrowanim");
    }

    currentAllItems.toggleClass("all-items-hide");
    currentSelectedItem.toggleClass("arrowanim");

    if(currentAllItems.parent().hasClass("custom-select--overlay-mobile") && $(window).width() <= 992){
      currentAllItems.toggleClass("active");
      currentSelectedItem.toggleClass("arrowanim");
    }

    e.stopPropagation();
  });

  $(document).on("click", function () {
    var opened = $(".all-items:not(.all-items-hide)") //,
    // index = opened.parent().index();

    // Hide "all-items" if it's not inside a "custom-select--overlay-mobile"
    if (!opened.parent().hasClass("custom-select--overlay-mobile") || $(window).width() > 992) {
      customSelect
        // .eq(index)
        .find(".all-items")
        .addClass("all-items-hide");
      customSelect
        // .eq(index)
        .find(".selected-item")
        .removeClass("arrowanim");
    }
  });

  // Hide custom select options on window resize
  $(window).on('resize', function(){
    $('.custom-select .all-items').addClass("all-items-hide").removeClass("active");
    $('.custom-select .selected-item').removeClass('arrowanim');
  });

});
