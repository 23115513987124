$(document).ready(function () {
	// ----------------------------------------------
	// Toggle LIST View / GRID view

	// Change to LIST view
	$(".filter-view .btn-view.list").on("click", function () {
		listView();

		// Change button style
		$(".btn-view").removeClass("active");
		$(this).addClass("active");
	});

	// Change to GRID view
	$(".filter-view .btn-view.grid").on("click", function () {
		gridView();

		// Change button style
		$(".btn-view").removeClass("active");
		$(this).addClass("active");
	});

});

function listView () {
	// Technologies and Solutions
	$(".tech-solutions__wrapper").addClass("col-xl-12 col-lg-12 col-md-12 tech-solutions__wrapper--list");

	// Market Intelligence Reports
	$(".market-card__wrapper").each(function () {
		$(this).addClass("col-md-12 market-card__wrapper--list");
		// Make it clickable
		$(this).click(function () {
			// Check element class
			if ($(this).hasClass("market-card__wrapper--list")) {
				let cardLink = $(this).find(".arrow-link").attr('href');
				window.location = cardLink;
			}
		});
	});

	// Market Intelligence Part
	$(".market-intelligence__slider .slide").each(function () {
		$(this).parent().addClass("market-intelligence__slider--list");
		// Make it clickable
		$(this).click(function () {
			// Check parent class
			if ($(this).parent().hasClass("market-intelligence__slider--list")) {
				let cardLink = $(this).find(".arrow-link").attr('href');
				window.location = cardLink;
			}
		});
	});

	// Events Overview
	$(".event-card__wrapper").each(function () {
		$(this).parent().addClass("events-list--list");
		$(this).addClass("col-md-12");
	});
	$(".highlighted-event").each(function () {
		$(this).addClass("highlighted-event--list");
	});

	// Partners Overview
	$(".partner-list .partner").each(function () {
		$(this).closest(".partner-list").addClass("partner-list--list");
		$(this).addClass("col-md-12 partner--list");
	});

	updateStyleStatus('list')
}

function gridView () {
	// Technologies and Solutions
	$(".tech-solutions__wrapper").removeClass("col-xl-12 col-lg-12 col-md-12 tech-solutions__wrapper--list")

	// Market Intelligence Reports
	$(".market-card__wrapper").each(function () {
		$(this).removeClass("col-md-12 market-card__wrapper--list");
	});

	// Market Intelligence Part
	$(".market-intelligence__slider .slide").each(function () {
		$(this).parent().removeClass("market-intelligence__slider--list");
	});

	// Events Overview
	$(".event-card__wrapper").each(function () {
		$(this).parent().removeClass("events-list--list");
		$(this).removeClass("col-md-12");
	});
	$(".highlighted-event").each(function () {
		$(this).removeClass("highlighted-event--list");
	});

	// Partners Overview
	$(".partner-list .partner").each(function () {
		$(this).closest(".partner-list").removeClass("partner-list--list");
		$(this).removeClass("col-md-12 partner--list");
	});

	updateStyleStatus('grid')
}

function updateStyleStatus (newStatus) {
	const styleStatus = document.querySelector('#view-style-status')
	if (!styleStatus || !newStatus) return

	styleStatus.value = newStatus
}
