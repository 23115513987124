$( document ).ready(function() {

    $(".next-events-part").each(function(){
        let nextEvents = $(this);
        // Create slick carousel if there are more than 3 slides
        // Or screen width is <= 992px
      if(nextEvents.find(".slide").length > 3){
        $(nextEvents).find('.next-events__slider').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,

            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  }
                },
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
                }
            ]
        });
      } else {
        // If there are less than 3 slides, add class "notSlider"
        nextEvents.addClass("notSlider");
      }
    });

});