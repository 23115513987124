$( document ).ready(function() {

    // Add new REFERENCE
    $(".tech-info__details button.add-reference").on( "click", function() {
        
        // Hide button
        $(this).hide();

        // Show "add-reference-inputs"
        $(".add-reference-inputs").slideDown("fast");

    });

    // Close "add-reference-inputs"
    $(".add-reference-inputs .btn-cancel").on( "click", function() {
        
        // show button
        $(".tech-info__details button.add-reference").show();

        // Hide "add-reference-inputs"
        $(".add-reference-inputs").slideUp("fast");

    });

});