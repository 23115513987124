$( document ).ready(function() {
    $(".tech-solutions__slider").each(function(){

        let trl = $(this).parent();

        $(this).rangeslider({
            polyfill: false,
        
            // Default CSS classes
            rangeClass: 'rangeslider',
            disabledClass: 'rangeslider--disabled',
            horizontalClass: 'rangeslider--horizontal',
            verticalClass: 'rangeslider--vertical',
            fillClass: 'rangeslider__fill',
            handleClass: 'rangeslider__handle',
        
            // Callback function
            onInit: function() {
                // Create TRL Scale text container in the handler
                trl.find(".rangeslider__handle").prepend("<div class='val'></div>");

                // Update TRL Scale text
                trl.find(".val").html(this.value);
            },
        
            // Callback function
            onSlide: function(position, value) {
                // Update TRL Scale text on slide
                trl.find(".val").html(this.value);
            },
        
            // Callback function
            onSlideEnd: function(position, value) {}
        });
    });

});